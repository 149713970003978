import React, { createContext, Dispatch, Reducer, useContext, useEffect, useReducer } from "react";
import { getSessionStorage, setSessionStorage } from "../utils/local-storage";
import { same } from "../utils/objects";

export enum AppContextActionType {
  Theme = "THEME",
  Title = "TITLE",
  Crumbs = "CRUMBS",
  Nav = "NAV",
}

export type AppContextAction = {
  type: AppContextActionType;
  payload: any;
};

export class AppContextNavState {
  constructor(public pin: boolean, public expand: boolean, public hover: boolean) {}

  get open() {
    return this.pin || this.expand || this.hover;
  }

  toJSON() {
    return { pin: this.pin, expand: this.expand, hover: this.hover };
  }
}

export type AppContextState = {
  theme: string;
  title: string;
  crumbs: string[];
  nav: AppContextNavState;
};

export const defaultState: AppContextState = {
  title: "Reclaim – A smart friend for your calendar",
  crumbs: ["Reclaim"],
  theme: "light",
  nav: new AppContextNavState(true, false, false),
};

export const appContextReducer: Reducer<AppContextState, AppContextAction> = (state, action) => {
  switch (action.type) {
    case AppContextActionType.Theme:
      const theme = action.payload;
      return theme !== state.theme ? { ...state, theme } : state;
    case AppContextActionType.Title:
      const title = action.payload ? action.payload : defaultState.title;
      return title !== state.title ? { ...state, title } : state;
    case AppContextActionType.Crumbs:
      const crumbs = action.payload?.length ? action.payload : defaultState.crumbs;
      return crumbs !== state.crumbs ? { ...state, crumbs } : state;
    case AppContextActionType.Nav:
      const next = {
        ...state.nav.toJSON(),
        ...action.payload,
      };
      return !same(next, state.nav)
        ? { ...state, nav: new AppContextNavState(next.pin, next.expand, next.hover) }
        : state;
    default:
      console.warn(`Unhandled action type: ${action.type}`);
      return state;
  }
};

export type AppContextValue = {
  state: AppContextState;
  dispatch: Dispatch<AppContextAction>;
};

const loadReducerState = () => {
  const persisted = getSessionStorage("app", defaultState);
  return {
    ...persisted,
    nav: new AppContextNavState(persisted.nav.pin, persisted.nav.expand, persisted.nav.hover),
    title: defaultState.title,
    crumbs: defaultState.crumbs,
  };
};

const defaultContext = {
  state: loadReducerState(),
  dispatch: (action: AppContextAction) => console.error("Dispatch called before initialized", action),
};

export const AppContext = createContext<AppContextValue>(defaultContext);

export function useAppContext() {
  return useContext<AppContextValue>(AppContext);
}

export const AppContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appContextReducer, defaultState, loadReducerState);

  /**
   * Update session storage when app state changes
   */
  useEffect(() => {
    setSessionStorage("app", {
      theme: state.theme,
      nav: state.nav,
    });
  }, [state.theme, state.nav]);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export default AppContext;
