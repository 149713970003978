import { forwardRef, useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { RadioBox, RadioBoxProps } from "../RadioBox";
import { RadioBoxGroupControlContext } from "./RhfRadioBoxGroupControl";

export type RhfRadioBoxProps = Omit<RadioBoxProps, "checked" | "orientation">;

export const RhfRadioBox = forwardRef<HTMLDivElement, RhfRadioBoxProps>((radioBoxProps, ref) => {
  const { orientation, fieldName } = useContext(RadioBoxGroupControlContext);
  const form = useFormContext();
  const val = form.watch(fieldName);
  const checked = useMemo(() => val === radioBoxProps.value, [radioBoxProps.value, val]);

  return <RadioBox {...radioBoxProps} ref={ref} checked={checked} orientation={orientation} />;
});
