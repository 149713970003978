import DateFnsUtils from "@date-io/date-fns";
import { addDays, eachWeekOfInterval, endOfMonth } from "date-fns";
import { WeekdayIndex } from "./dates";

export class CustomDateFnsUtils extends DateFnsUtils {
  weekdays = ["S", "M", "T", "W", "T", "F", "S"];

  startOfWeek: WeekdayIndex = 0;

  getWeekdays(): string[] {
    const days = [...this.weekdays];
    const split = days.splice(0, this.startOfWeek);
    return days.concat(split);
  }

  getWeekArray(date: Date): Date[][] {
    const weekArray: Date[][] = [];
    const weekIntervalStarts = eachWeekOfInterval({start: date, end: endOfMonth(date) }, { weekStartsOn: this.startOfWeek });
    
    weekIntervalStarts.forEach((start, i) => {
      weekArray[i] = [start];
      while (weekArray[i].length < 7) {
        weekArray[i].push(addDays(weekArray[i][weekArray[i].length - 1], 1));
      }
    });

    return weekArray;
  };

  constructor({ locale }) {
    super({ locale });
  }
}

class MondayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 1;
}
class TuesdayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 2;
}
class WednesdayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 3;
}
class ThursdayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 4;
}
class FridayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 5;
}
class SaturdayDateFnsUtils extends CustomDateFnsUtils {
  startOfWeek: WeekdayIndex = 6;
}

/**
 * Because of how the date picker internals are setup we need to provide a constructor instance
 * with the start of week index preset.
 */
export const DateFnsUtilsDaysArray = [
  CustomDateFnsUtils,
  MondayDateFnsUtils,
  TuesdayDateFnsUtils,
  WednesdayDateFnsUtils,
  ThursdayDateFnsUtils,
  FridayDateFnsUtils,
  SaturdayDateFnsUtils,
];
