/**
 * Options for getCallStack
 */
export interface GetCallStackOptions {
  /**
   * Whether or not to include frames from Javascript files (default: `false`)
   */
  includeJS?: boolean;
  /**
   * The level of detail to show in each path.
   *
   * `all`: full path
   * `local`: just the local path, trims webpack data etc etc (default)
   * `file`: just the filename
   */
  detail?: "all" | "local" | "file";
  /**
   * Trims frames off the top of the stack.  Useful if you know what the stack will be to a certain depth. (default: `0`)
   */
  trimFrames?: number;
  /**
   * Format stack from error rather than generating a new one.
   */
  error?: Error;
}

/**
 * Gets the current call stack as an array of strings
 * @param options Options
 * @returns A call stack as an array of strings
 */
export const getCallStack = (options?: GetCallStackOptions) => {
  const trimMe = !options?.error;

  const { includeJS, detail, trimFrames, error } = {
    includeJS: false,
    detail: "local",
    trimFrames: 0,
    error: new Error(),
    ...options,
  };

  let stack = error.stack?.split("\n") || [];

  if (!includeJS) stack = stack.filter((l) => /\.tsx?:\d+:\d+$/.test(l));

  switch (detail) {
    case "local":
      stack = stack.map((l) => l.replace(/.+\/\/\//, ""));
      break;
    case "file":
      stack = stack.map((l) => l.replace(/.+(?=\/)/, ""));
      break;
  }

  stack?.splice(0, trimMe ? trimFrames + 1 : trimFrames);

  return stack;
};

/**
 * Does its darndest an error message in an object of unkown type
 * @param error The error object
 * @param foundErrors **DO NOT USE** - only for recursion
 * @returns A string message representing the error object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findErrorMessage = (error: any, foundErrors = new Set<unknown>()): string => {
  try {
    const stack = getCallStack({ trimFrames: foundErrors.size * 2 + 1 }).join("\n");

    if (typeof error === "object" && foundErrors.has(error)) return "\n\n**** Found recursive object ****";
    foundErrors.add(error);

    if (!error) return `Empty error:\n${stack}`;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (error instanceof Error) return stringFromError(error, foundErrors);
    if (typeof error === "string") return `${error}:\n${stack}`;
    if (error.message) return findErrorMessage(error.message, foundErrors);
    if (error.error) return findErrorMessage(error.error, foundErrors);

    try {
      return JSON.stringify(error);
    } catch {
      try {
        return error.toString();
      } catch {
        return `${error}`;
      }
    }
  } catch (e) {
    return `\n\n**** Failed to find error message: ${e} ****`;
  }
};

/**
 * Turns an error into a string with a call stack and "caused by" details
 * @param error The error
 * @param foundErrors **DO NOT USE** - only for recursion
 * @returns A string message
 */
export const stringFromError = (error: Error, foundErrors?: Set<unknown>) =>
  error.toString() +
  `\n${getCallStack({ error }).join("\n")}` +
  (error.cause
    ? `\n\n--------------\n| caused by: |\n--------------\n\n${findErrorMessage(error.cause, foundErrors)}`
    : "");

export const catchWithMessage = (doTry: () => unknown, doCatch: (e: unknown, message: string) => unknown) => {
  try {
    doTry();
  } catch (e) {
    doCatch(e, findErrorMessage(e));
  }
};

export const catchWithMessageAsync = async (
  doTry: () => unknown,
  doCatch: (e: unknown, message: string) => unknown
) => {
  try {
    await doTry();
  } catch (e) {
    doCatch(e, findErrorMessage(e));
  }
};
