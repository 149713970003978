import { FunctionComponent, JSXElementConstructor, ReactElement } from "react";
import { FieldError } from "react-hook-form";
import { isType } from "../../../types";

export type RhfControlRenderProps = {
  name: string | `${string}.${string}` | `${string}.${number}`;
  errors?: FieldError;
};

export type RhfControlProps = {
  name: string | `${string}.${string}` | `${string}.${number}`;
};
export interface IRhfControl<P extends RhfControlProps> extends FunctionComponent<P> {
  isControl: true;
  isController?: boolean;
}

export function isRhfControl<C extends JSXElementConstructor<P>, P = {}>(c: C) {
  return isType<C>(c, (c) => c["isControl"]);
}

export function isRhfControlElement<E extends ReactElement>(e: E) {
  return typeof e.type !== "string" && isRhfControl(e.type);
}
