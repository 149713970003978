import { ReclaimApiError as ReclaimApiErrorSchedulingLinks } from "../reclaim-api/scheduling-links/scheduling-links-client";
import { ReclaimApiError as ReclaimApiErrorTeam } from "../reclaim-api/team/client";
import { movePropsOver } from "../utils/objects";
import { NetworkError, ReclaimApiError } from "./client";
import { ReclaimErrorBody, ReclaimErrorCode } from "./types";
export { NetworkError as AppNetworkError };

export class AppReclaimApiError<
  E extends { status: number; response?: unknown | null } = { status: number; response?: unknown | null }
> extends ReclaimApiError<E> {
  readonly reclaimErrorCode?: ReclaimErrorCode;

  constructor(err: AppReclaimApiError<E> | ReclaimApiErrorSchedulingLinks<E> | ReclaimApiErrorTeam<E>) {
    super(
      {
        status: err.status,
        statusText: err.statusText || "",
        headers: err.headers,
        data: err.body,
        config: {},
      },
      err.message
    );

    movePropsOver(err, this);

    let bodyObj: ReclaimErrorBody | undefined;
    switch (typeof err.body) {
      case "string":
        try {
          bodyObj = JSON.parse(err.body);
        } catch {}
        break;
      case "object":
        bodyObj = (err.body as ReclaimErrorBody) || undefined;
        break;
    }

    if (bodyObj?.reclaimError && bodyObj.errorCode) this.reclaimErrorCode = bodyObj.errorCode as ReclaimErrorCode;
  }
}
