import { VFC } from "react";
import { useQuestsActions } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Override } from "../../types";
import { YoutubeVideoPlayerModal, YoutubeVideoPlayerModalProps } from "../YoutubeVideoPlayerModal";
import { QuestStep, QuestStepConfig } from "./quests.types";

export type QuestVideoPlayerModalProps = Override<
  YoutubeVideoPlayerModalProps,
  {
    step: QuestStepConfig<QuestStep>;
    onClose?: () => void;
  }
>;

export const QuestVideoPlayerModal: VFC<QuestVideoPlayerModalProps> = ({ step, onClose, ...rest }) => {
  const { onStepComplete } = useQuestsActions();

  const handleClose = useCallbackSafeRef(() => {
    onStepComplete(step.id);
    onClose?.();
  });

  return (
    <YoutubeVideoPlayerModal
      onClose={handleClose}
      videoLink={step.videoLink}
      videoOptions={step.videoOptions}
      {...rest}
    />
  );
};
