import { useEffect, useState } from "react";

// Solution to JSS classname mismatching in Next as discussed in this thread
// https://stackoverflow.com/questions/50685175/react-material-ui-warning-prop-classname-did-not-match/70461229#70461229
// CK 11/22

const ClientOnly = ({ children }) => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? children : null;
};

export default ClientOnly;
