import { atom, useAtom } from "jotai";
import { pipe, subscribe } from "wonka";
import { reclaim } from "../../reclaim-api";
import { Moment } from "../../reclaim-api/Moment";

const momentAtom = atom<Moment | null>(null);
let timer: NodeJS.Timeout;

/**
 * Create a single global subcription for components to connect to. Onmount
 * will be called after the first component uses this hook. If no components
 * are actively using hook the unmount will unsubscibe. Additionally, set up
 * interval polling.
 */
momentAtom.onMount = (setMomentAtom) => {
  const { unsubscribe } = pipe(
    reclaim.moment.listAndWatch$$(),
    subscribe((moment: Moment) => {
      void setMomentAtom(moment);
    })
  );

  const now = new Date();

  const updateTimer = async () => {
    const moment = await reclaim.moment.list();
    void setMomentAtom(moment);
    clearTimeout(timer);
    timer = setTimeout(updateTimer, 1000 * 60); // 1min
  };

  // We want the update to trigger 1 second after every new minute.
  // HH:MM:01
  const secondsUntilStart = (61 - now.getSeconds()) * 1000;

  setTimeout(() => updateTimer(), secondsUntilStart);

  return () => {
    clearTimeout(timer);
    unsubscribe();
  };
};

export const useMoment = (): Moment | null => {
  const [moment] = useAtom(momentAtom);

  return moment;
};
