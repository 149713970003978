import { Domain } from "./types";

export class SlackDomain extends Domain {
  public link(userId: string, teamId: string) {
    return this.api.slack.link<{ status: 404; response: "Slack user not found" }>({ userId, teamId }, {});
  }

  authRedirect(teamId?: string) {
    // FIXME: (IW) Doesn't work when base uri is just a path (eg. '/oauth/login'),
    // but window.location is no bueno
    const authUrl = new URL(`${this.api.baseUrl}/slack/add`, window.location.href);
    if (teamId) authUrl.searchParams.append("teamId", teamId);

    window.location.href = authUrl.toString();
  }
}
