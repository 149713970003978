import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../utils/local-storage";
import { browser } from "../utils/platform";

export const LocalStorageSharedContext = createContext<{
  state: { [key: string]: any };
  getItem: (key: string) => any;
  setItem: (key: string, value: any) => void;
}>({
  state: {},
  getItem: () => null,
  setItem: () => {},
});

export const LocalStorageSharedContextProvider: React.FC<{ initialState?: { [key: string]: any } }> = ({
  initialState = {},
  children,
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!browser().isBrowser) return;

    const listener = ({ key, newValue }) => {
      if (null === newValue) {
        return setState((prev) => {
          const next = { ...prev };
          delete next[key];
          return next;
        });
      }

      setState((prev) => {
        const next = { ...prev };
        try {
          next[key] = JSON.parse(newValue);
        } catch (error) {
          console.warn("Unexpected localStorage value change", key, newValue);
          next[key] = newValue;
        }
        return next;
      });
    };

    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, []);

  const getItem = useCallback(
    (key: string) => {
      if (!state[key]) {
        const val = getLocalStorage(key, null);
        state[key] = val;
      }
      return state[key];
    },
    [state]
  );

  const setItem = useCallback((key: string, value: any) => {
    if (null !== value && undefined !== value) setLocalStorage(key, value);
    else removeLocalStorage(key);

    setState((prev) => {
      const next = { ...prev };
      if (null !== value && undefined !== value) next[key] = value;
      else delete next[key];
      return next;
    });
  }, []);

  return (
    <LocalStorageSharedContext.Provider
      value={{
        state,
        getItem,
        setItem,
      }}
    >
      {children}
    </LocalStorageSharedContext.Provider>
  );
};

export function useLocalStorage<T>(
  key: string,
  defaultValue?: T | (() => T),
  deserialize?: (obj: any) => T,
  serialize?: (obj: T) => any
): [T, (value: T | null) => void];
export function useLocalStorage<T>(
  key: string,
  defaultValue: undefined,
  deserialize?: (obj: any) => T,
  serialize?: (obj: T) => any
): [T | null, (value: T | null) => void];
export function useLocalStorage<T>(
  key: string,
  defaultValue?: T | (() => T),
  deserialize: (obj: any) => T = (obj) => obj,
  serialize: (obj: T) => any = (obj) => obj
): [T | null, (value: T | null) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const { getItem, setItem } = useContext(LocalStorageSharedContext);

  const state = useMemo(() => {
    const getDefaultValue: () => T | undefined =
      typeof defaultValue === "function" ? (defaultValue as () => T) : () => defaultValue;

    const value = getItem(key);
    try {
      return !!value && value !== null ? deserialize(value) : (getDefaultValue() as T) || null;
    } catch (e) {
      console.error(`Error deserializing from storage: ${e}`);
      return (getDefaultValue() as T) || null;
    }
  }, [defaultValue, deserialize, getItem, key]);

  return [
    state,
    (value) => {
      setItem(key, value !== null ? serialize(value) : null);
    },
  ];
}
