import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useState, VFC } from "react";
import { ConfirmDialog, ConfirmDialogProps } from "./ConfirmDialog";

export type EasyConfirmDialogOpenerProps = Omit<ConfirmDialogProps, "open">;

const propsAtom = atom<EasyConfirmDialogOpenerProps | undefined>(undefined);
const propsWriteAtom = atom(null, (get, set, newProps: EasyConfirmDialogOpenerProps | undefined) =>
  set(propsAtom, newProps)
);

export const EasyConfirmDialog: VFC = () => {
  const [props] = useAtom(propsAtom);
  const [open, setOpen] = useState(false);
  const onClose = props?.onClose;

  const handleClose = useCallback<NonNullable<ConfirmDialogProps["onClose"]>>(
    (e) => {
      setOpen(false);
      onClose?.(e);
    },
    [onClose]
  );

  useEffect(() => {
    if (props) setOpen(true);
  }, [props]);

  return props ? (
    <ConfirmDialog open={open} {...props} onClose={handleClose} onCancel={handleClose} onSuccess={handleClose} />
  ) : (
    <></>
  );
};

export type EasyConfirmDialogOpener<P extends Partial<EasyConfirmDialogOpenerProps>> = (
  props: Partial<EasyConfirmDialogOpenerProps> & Omit<EasyConfirmDialogOpenerProps, keyof P>
) => Promise<void>;

export const useEasyConfirmDialog = <P extends Partial<EasyConfirmDialogOpenerProps>>(
  baseProps: P
): EasyConfirmDialogOpener<P> => {
  const [, setProps] = useAtom(propsWriteAtom);
  return useCallback<EasyConfirmDialogOpener<P>>(
    (props) =>
      new Promise(async (res) => {
        await setProps({
          ...baseProps,
          ...props,
          onClose: (e) => {
            (props.onClose || baseProps.onClose)?.(e);
            res();
          },
        } as EasyConfirmDialogOpenerProps);
      }),
    [baseProps, setProps]
  );
};
