export function getLocalStorage(key: string, defaultValue?: unknown) {
  if (typeof localStorage === "undefined") return defaultValue;

  const value = localStorage.getItem(key);
  if (null === value) return defaultValue;

  try {
    return JSON.parse(value);
  } catch (error) {
    console.warn("Unexpected localStorage value", key, value);
    return value;
  }
}

export function removeLocalStorage(key: string) {
  return localStorage.removeItem(key);
}

export function setLocalStorage(key: string, value?: unknown) {
  return undefined !== value && null !== value
    ? localStorage.setItem(key, JSON.stringify(value))
    : removeLocalStorage(key);
}

export function getSessionStorage(key: string, defaultValue?: unknown) {
  if (typeof sessionStorage === "undefined") return defaultValue;

  const value = sessionStorage.getItem(key);
  if (null === value) return defaultValue;

  try {
    return JSON.parse(value);
  } catch (error) {
    console.warn("Unexpected sessionStorage value", key, value);
    return value;
  }
}

export function removeSessionStorage(key: string) {
  return sessionStorage.removeItem(key);
}

export function setSessionStorage(key: string, value?: unknown) {
  return undefined !== value && null !== value
    ? sessionStorage.setItem(key, JSON.stringify(value))
    : removeSessionStorage(key);
}
