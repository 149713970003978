import { useCallback, useRef } from "react";

/**
 * Returns a function that behaves identically to the one passed in, but whose reference never changes
 * @param callback The functional callback
 * @returns A function whose reference never changes
 */
export const useCallbackSafeRef = <T extends (...args: unknown[]) => unknown>(callback: T): T => {
  const outCb = useRef(callback);
  outCb.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => outCb.current(...args)) as T, []);
};
