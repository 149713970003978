import { ThinPerson } from "./client";
import { Domain } from "./types";

export const LOGIN_BASE_URI = process.env.NEXT_PUBLIC_LOGIN_BASE_URI || "/oauth/login";
export const LOGOUT_URI = process.env.NEXT_PUBLIC_LOGOUT_URI || "/logout";

export type { ThinPerson };

export class PeopleDomain extends Domain {
  unsubscribe = (email: string, type: string) => this.api.people.unsubscribe({ email, type });

  search = (query: string, limit: number = 20) => this.api.people.search({ q: query, limit });
}
