import { Box, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { createContext } from "react";
import { IRhfControl } from ".";
import { RhfRadioGroupControl, RhfRadioGroupControlProps } from "./RhfRadioGroupControl";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2.5),
      width: "100%",
    },
    content: {
      display: "flex",
    },
    vertical: {
      flexDirection: "column",
      gap: 0,
    },
    horizontal: {
      flexDirection: "row",
      gap: theme.spacing(1),
      justifyContent: "center",
    },
  }),
  {
    classNamePrefix: "RhfRadioBoxGroupControl",
  }
);

export const RadioBoxGroupControlContext = createContext<{
  orientation: RadioBoxGroupControlOrientation;
  fieldName: string;
}>({
  orientation: "vertical",
  fieldName: "",
});

export type RadioBoxGroupControlJSSClassKey = keyof ReturnType<typeof useStyles>;
export type RadioBoxGroupControlOrientation = "horizontal" | "vertical";

export type RhfRadioBoxGroupControlProps = RhfRadioGroupControlProps & {
  classes?: Partial<ClassNameMap<RadioBoxGroupControlJSSClassKey>>;
  className?: string;
  orientation?: RadioBoxGroupControlOrientation;
};

export const RhfRadioBoxGroupControl: IRhfControl<RhfRadioBoxGroupControlProps> = ({
  className,
  classes: extClasses,
  orientation = "vertical",
  children,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <RadioBoxGroupControlContext.Provider
      value={{
        orientation,
        fieldName: rest.name,
      }}
    >
      <RhfRadioGroupControl className={clsx(classes.root, className)} aria-label={rest.name} {...rest}>
        <Box
          className={clsx(classes.content, {
            [classes.vertical]: orientation === "vertical",
            [classes.horizontal]: orientation === "horizontal",
          })}
        >
          {children}
        </Box>
      </RhfRadioGroupControl>
    </RadioBoxGroupControlContext.Provider>
  );
};

RhfRadioBoxGroupControl.isControl = RhfRadioGroupControl.isControl;
RhfRadioBoxGroupControl.isController = RhfRadioGroupControl.isController;
