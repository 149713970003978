import { atom, useAtom } from "jotai";

/**
 * Global interval that sets a new Date() every TIMEOUT ms.
 */
const TIMEOUT = 1000 * 60; // 1min

const nowAtom = atom<Date>(new Date());

nowAtom.onMount = (setNowAtom) => {
  const now = new Date();
  let timer: NodeJS.Timeout;

  const updateTimer = () => {
    void setNowAtom(new Date());
    timer = setTimeout(updateTimer, TIMEOUT); // 1min
  };

  // We want the update to trigger 1 second after every new minute.
  // HH:MM:01
  const secondsUntilStart = (61 - now.getSeconds()) * 1000;

  setTimeout(() => updateTimer(), secondsUntilStart);

  return () => clearTimeout(timer);
};

export const useNow = (): Date => {
  const [now] = useAtom(nowAtom);

  return now;
};
