import { useRef } from "react";

/**
 * Runs an effect once
 * @param effect the effect to run
 * @param test if false, will delay running the effect until the first frame that test is true
 * @deprecated This actually runs the effect twice in most cases, do not use
 */
export const useRunEffectOnce = (effect: () => void, test: boolean = true) => {
  const ran = useRef(false);

  if (!ran.current && test) {
    effect();
    ran.current = true;
  }
};
