export abstract class ObjectEnum<KeyType = string> {
  static get(key?: string) {
    if (!key) return undefined;

    return Object.values(this)
      .filter((k) => k instanceof this)
      .find((val) => val.key === key);
  }

  constructor(public readonly key: KeyType) {}

  toString() {
    return this.key;
  }

  toJSON(): KeyType | null {
    return this.key;
  }
}