import { ChangeEvent, MouseEvent } from "react";
import { Timer } from "../types";

export function isDescendant(parent: string, child?: EventTarget | null) {
  if (!child) return false;

  // @ts-ignore
  var node: HTMLElement = child.parentNode;
  while (node != null && node.tagName !== "document") {
    if (node.className?.split(" ").includes(parent)) {
      return true;
    }
    // @ts-ignore
    node = node.parentNode;
  }
  return false;
}

export function isDescendantOfEl(parent: Node, child: Node): boolean {
  let node: Node | null = child.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}

export function findNearestParent(element: Element, selector: string): Element | undefined {
  if (element.matches(selector)) return element;
  if (element.parentElement) return findNearestParent(element.parentElement, selector);
  return undefined;
}

export function getActiveScrollParent(node) {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight && node.offsetHeight && node.scrollTop !== 0) {
    return node;
  } else {
    return !!node.parentNode ? getActiveScrollParent(node.parentNode) : null;
  }
}

export const preventPropagation = (e: MouseEvent<HTMLElement> | ChangeEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

/**
 * Waits for a child window to close via polling.
 * @param w The window to poll for
 * @param onClose A callback to call on close
 * @param pollInterval the time between pollings
 * @returns A function to stop polling
 */
export const onChildWindowClose = (w: Window | undefined, onClose: () => unknown, pollInterval = 500): (() => void) => {
  let timer: Timer;
  const stopPolling = () => clearTimeout(timer);
  const continuePolling = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    timer = setTimeout(timerCb, pollInterval);
  };

  const timerCb = async () => {
    if (!w) stopPolling();
    else if (w.closed) {
      stopPolling();
      await onClose();
    } else continuePolling();
  };

  continuePolling();

  return stopPolling;
};

/**
 * Gets a text offset in one element relative to a parent element.
 * @param relativeElement The element to find the cursor position relative to
 * @param immediateParentNode The immediate parent of the cursor
 * @param offsetInParentNode The offset of the cursor in `immediateParentNode`
 * @param totalOffset USED ONLY FOR RECURSION.  DO NOT USE THIS PROPERTY.
 * @returns A number representing the offset relative to the parent element or `undefined` if the cursor position could not be found.
 */
export function getOffsetRelativeToElemeent(
  relativeElement: HTMLElement,
  immediateParentNode: Node,
  offsetInParentNode: number,
  totalOffset = 0
): number | undefined {
  const foundOffset = !Array.from(relativeElement.childNodes).every((n) => {
    if (n === immediateParentNode) {
      totalOffset += offsetInParentNode;
      return false;
    } else {
      const textLength = n.textContent?.length || 0;

      switch (n.nodeType) {
        case 1: // Element
          const newTotalOffset = getOffsetRelativeToElemeent(
            n as HTMLElement,
            immediateParentNode,
            offsetInParentNode,
            totalOffset
          );
          if (newTotalOffset === undefined) {
            totalOffset += textLength;
            return true;
          } else {
            totalOffset = newTotalOffset;
            return false;
          }
        case 3: // Text
          totalOffset += textLength;
          return true;
      }
    }
  });

  return foundOffset ? totalOffset : undefined;
}

/**
 * Gets the immediate parent node, and local text-offset given a parent node, and an offset from that parent node.
 * @param parentElement The enclosing parent element
 * @param totalOffset The total text-offset in `parentElement`
 * @returns The immediate parent node in which the offset ends, as well as the local offset in that node, or undefined if none can be found.
 */
export function getLocalOffset(
  parentElement: HTMLElement,
  totalOffset: number
): { node: Node; offset: number } | undefined {
  let ret: { node: Node; offset: number } | undefined;

  Array.from(parentElement.childNodes).every((n) => {
    const textLength = n.textContent?.length || 0;

    if (totalOffset < textLength) {
      ret = {
        node: n,
        offset: totalOffset,
      };
      return false;
    } else {
      switch (n.nodeType) {
        case 1: // Element
          const result = getLocalOffset(n as HTMLElement, totalOffset);
          if (result) {
            ret = result;
            return false;
          } else {
            totalOffset -= textLength;
            return true;
          }
        case 3: // Text
          totalOffset -= textLength;
          return true;
      }
    }
  });

  return ret;
}
