import { Divider } from "@material-ui/core";
import { Fragment, isValidElement, MouseEvent, ReactElement, RefObject, useCallback, useMemo, useState } from "react";
import { useIsMountedRef } from "../hooks/useIsMountedRef";
import { UIAction, UIActionObject, UIActionWrapper } from "../types/uiActions";

export type UIActionMouseEventHandler = (e: MouseEvent) => Promise<unknown>;
export type UIActionElementOnClickHandler = (e: MouseEvent, promise: Promise<void>) => Promise<unknown> | unknown;
export type UIActionElementPostActionHandler = (e: MouseEvent) => Promise<unknown> | unknown;

export interface UseUIActionReturnType {
  handleClick: UIActionMouseEventHandler;
  thinking: boolean;
}

export interface UIActionRendererInfo<IS_LINK extends boolean> {
  actionObject: UIActionObject<IS_LINK>;
  isLink: IS_LINK;
}

export type UIActionRenderer = <IS_LINK extends boolean>(info: UIActionRendererInfo<IS_LINK>) => ReactElement;

export function useUIAction(
  uiAction: UIAction,
  ref: RefObject<HTMLElement>,
  onClick: UIActionElementOnClickHandler | undefined,
  postAction: UIActionElementPostActionHandler | undefined,
  forceDisable?: boolean
): UseUIActionReturnType {
  const action = (uiAction as UIActionObject | undefined)?.action;
  const [thinking, setThinking] = useState(false);
  const disabled = !!(uiAction as UIActionObject | undefined)?.disabled || !!forceDisable || thinking;
  const isMountedRef = useIsMountedRef();

  const handleClick = useCallback<UIActionMouseEventHandler>(
    async (e) => {
      e.stopPropagation();
      if (disabled) return;

      setThinking(true);
      if (!action || typeof action === "string") return;
      const promise = (async () => void (await action(ref)))();
      await promise;
      await onClick?.(e, promise || Promise.resolve());
      await postAction?.(e);
      if (isMountedRef.current) setThinking(false);
    },
    [action, disabled, isMountedRef, onClick, postAction, ref]
  );

  return useMemo<UseUIActionReturnType>(() => ({ handleClick, thinking }), [handleClick, thinking]);
}

export function renderUIAction(
  wrapperType: UIActionWrapper,
  uiAction: UIAction,
  renderer: UIActionRenderer
): ReactElement {
  if (!uiAction) return <Fragment />;
  else if (typeof uiAction === "function") return uiAction(wrapperType);
  else if (isValidElement(uiAction)) return uiAction;
  else
    return renderer({
      actionObject: uiAction,
      isLink: typeof uiAction.action === "string",
    });
}

export const menuDivider = () => <Divider />;
