import { useEffect } from "react";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

export const useWindowEventListener = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => unknown
) => {
  const safeReffed = useCallbackSafeRef(listener);
  useEffect(() => {
    window.addEventListener(type, safeReffed);
    return () => window.removeEventListener(type, safeReffed);
  }, [safeReffed, type]);
};

export const useDocumentEventListener = <K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => unknown
) => {
  const safeReffed = useCallbackSafeRef(listener);
  useEffect(() => {
    document.addEventListener(type, safeReffed);
    return () => document.removeEventListener(type, safeReffed);
  }, [safeReffed, type]);
};
