import { makeStyles, PopperPlacementType, useMediaQuery, useTheme } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { MouseEventHandler, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useQuestsActions, useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { QuestOrb, QuestOrbProps } from "./QuestOrb";
import { ActiveQuest, GenericQuestStepConfig, QuestGroup } from "./quests.types";
import { getQuestsStepConfig } from "./quests.util";
import { QuestStepPopper } from "./QuestStepPopper";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    fullWidth: {
      width: "100%",
    },
  }),
  {
    classNamePrefix: "QuestStepWrapper",
  }
);

export type QuestStepWrapperJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestStepWrapperProps<QG extends QuestGroup> = {
  classes?: Partial<ClassNameMap<QuestStepWrapperJSSClassKey>>;
  className?: string;
  config: ActiveQuest<QG> | ActiveQuest<QG>[];
  hidden?: boolean;
  disabled?: boolean;
  QuestOrbProps?: QuestOrbProps;
  popperPlacement?: PopperPlacementType;
  fullWidth?: boolean;
  children?: ReactNode;
};

export const QuestStepWrapper = ({
  className,
  classes: extClasses,
  config,
  hidden,
  disabled,
  QuestOrbProps,
  popperPlacement,
  fullWidth,
  children,
}: QuestStepWrapperProps<QuestGroup>) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const orbRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLSpanElement | null>(null);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { activeQuest } = useQuestsState();
  const { onStepComplete } = useQuestsActions();

  const [popperWasDisplayed, setPopperWasDisplayed] = useState(false);
  const [popperActive, setPopperActive] = useState(false);

  const activeConfig = useMemo<ActiveQuest<QuestGroup> | undefined>(() => {
    if (activeQuest) {
      const cfg = Array.isArray(config) ? config : [config];
      return cfg.find(
        ({ group, quest, step }) =>
          activeQuest.group === group && activeQuest.quest === quest && activeQuest.step === step
      );
    }
  }, [config, activeQuest]);

  const stepConfig = useMemo<GenericQuestStepConfig | undefined>(
    () => (!activeConfig || !activeQuest ? undefined : getQuestsStepConfig(activeQuest)),
    [activeQuest, activeConfig]
  );

  const orbActive = !!(activeConfig && !hidden && stepConfig) && !!stepConfig;

  const handleClose = useCallbackSafeRef(() => {
    if (!activeConfig || !stepConfig) return;

    if (stepConfig.type === "orb") {
      onStepComplete(activeConfig.step);
    }

    setPopperActive(false);
  });

  const showStepPopover = useCallbackSafeRef(() => {
    if (!stepConfig?.popoverConfig || popperActive) return;

    setPopperActive(true);
    setPopperWasDisplayed(true);
  });

  const handleClick = useCallbackSafeRef<MouseEventHandler>((e) => {
    if (!stepConfig || !activeConfig || disabled) return;

    if (xs && stepConfig.type !== "action") {
      showStepPopover();
    } else if (stepConfig.type === "action") {
      onStepComplete(activeConfig.step);
    }
  });

  /**
   * Show popover if not shown yet and orb becomes active
   */
  useEffect(() => {
    let timer;

    if (orbActive && !popperWasDisplayed) {
      timer = setTimeout(() => {
        showStepPopover();
      }, 750);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [orbActive, popperWasDisplayed, showStepPopover]);

  return (
    <>
      <span
        ref={targetRef}
        className={clsx(classes.root, className, { [classes.fullWidth]: fullWidth })}
        onClick={handleClick}
      >
        {children}
        {orbActive && stepConfig.type !== "action" && (
          <QuestOrb
            ref={orbRef}
            type={stepConfig.type}
            onMouseEnter={showStepPopover}
            disabled={disabled}
            {...QuestOrbProps}
          />
        )}
      </span>
      {!!stepConfig && !hidden && (
        <QuestStepPopper
          open={popperActive}
          stepConfig={stepConfig}
          onClose={handleClose}
          anchorEl={stepConfig.type === "action" ? targetRef.current : orbRef.current}
          placement={popperPlacement}
        />
      )}
    </>
  );
};
