import { ObjectEnum } from "../types/enums";
import { Override } from "../types/index";
import {
  GroupedSummaryInsight as GroupedSummaryInsightDto,
  GroupedValueInsight as GroupedValueInsightDto,
  Insight as InsightDto,
  Insights as InsightsDto,
  SummaryInsight as SummaryInsightDto,
  ValueInsight as ValueInsightDto,
} from "./client";
import { TransformDomain } from "./types";

export class InsightUnit extends ObjectEnum {
  static TimeChunks = new InsightUnit("TIME_CHUNKS", "Time chunks");
  static Percent = new InsightUnit("PERCENT", "%");
  static Count = new InsightUnit("COUNT", "Count");

  constructor(public readonly feature: string, public readonly label: string) {
    super(feature);
  }
}
export type Insights = Override<
  InsightsDto,
  {
    values: Record<string, ValueInsight>;
    groupedValues: Record<string, GroupedValueInsight>;
    summaries: Record<string, SummaryInsight>;
    groupedSummaries: Record<string, GroupedSummaryInsight>;
  }
>;
export type BaseInsight = Override<
  InsightDto,
  {
    unit: InsightUnit;
  }
>;
export type ValueInsight = Override<
  ValueInsightDto,
  {
    value?: number;
    unit: InsightUnit;
  }
>;
export type GroupedValueInsight = Override<
  GroupedValueInsightDto,
  {
    unit: InsightUnit;
  }
>;
export type SummaryInsight = Override<
  SummaryInsightDto,
  {
    count: number;
    sum: number;
    min: number;
    max: number;
    unit: InsightUnit;
  }
>;
export type GroupedSummaryInsight = Override<
  GroupedSummaryInsightDto,
  {
    summaries: Record<string, SummaryInsight>;
    summary: SummaryInsight;
    unit: InsightUnit;
  }
>;
export type Insight = BaseInsight | ValueInsight | SummaryInsight | GroupedSummaryInsight;

function dtoToInsights(dto: InsightsDto): Insights {
  const insights: Insights = {
    ...dto,
    values: Object.entries(dto.values || {}).reduce((acc: Record<string, ValueInsight>, [key, val]) => {
      acc[key] = val as any;

      // convert unit to enum
      if (acc[key].unit) acc[key].unit = InsightUnit.get(acc[key].unit as unknown as string);

      // convert "Infinity" (string) to Infinity (built-in)
      if ("Infinity" === (acc[key].value as any)) acc[key].value = Infinity;
      return acc;
    }, {}),
    groupedValues: dto.groupedValues as unknown as Record<string, GroupedValueInsight>,
    summaries: dto.summaries as unknown as Record<string, SummaryInsight>,
    groupedSummaries: dto.groupedSummaries as unknown as Record<string, GroupedSummaryInsight>,
  };

  return insights;
}

export class InsightsDomain extends TransformDomain<Insights, InsightsDto> {
  resource = "Insights";
  cacheKey = "insights";
  pk = ["start", "end"];

  public deserialize = dtoToInsights;

  public basic = this.deserializeResponse(this.api.insights.basic);

  public assist = this.deserializeResponse(this.api.insights.assistant);

  public budget = this.deserializeResponse((start: Date, end: Date) => {
    return this.api.insights.budgetByCategory({ start: start.toJSON(), end: end.toJSON() });
  });
}
