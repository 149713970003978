import { CommandBarClientSDK } from "commandbar";
import { pipe, subscribe } from "wonka";
import { reclaim } from "../reclaim-api";
import { Task } from "../reclaim-api/Tasks";
import { byKey } from "./arrays";

/**
 * Tasks
 */

export const initTaskData = (cb?: CommandBarClientSDK) => {
  pipe(
    reclaim.tasks.listAndWatch$$({ instances: true }),
    subscribe(async (tasks?: Task[]) => {
      const filteredAndMapped = tasks?.map((t) => {
        const instances = (t.instances || [])
          .filter((instance) => (instance.end?.getTime() || 0) > new Date().getTime())
          .sort(byKey("start"));

        return {
          ...t,
          label: t.title,
          nextEvent: instances[0],
        };
      });

      cb?.addRecords("tasks", filteredAndMapped || []);
    })
  );
};

/**
 * Habits
 */

export const initHabitData = (cb?: CommandBarClientSDK) => {
  pipe(
    reclaim.habits.listAndWatch$$(),
    subscribe((habits) => {
      cb?.addRecords("habits", habits);
    })
  );
};
