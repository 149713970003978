/// <reference types="@types/segment-analytics" />
/// <reference types="@types/intercom-web" />

import React, { createContext, useContext } from "react";
import { Analytics } from "../analytics";

export type AnalyticsContextState = typeof Analytics;

export type AnalyticsContextValue = {
  state: AnalyticsContextState;
};

export const defaultState: AnalyticsContextState = Analytics;

export const AnalyticsContext = createContext<AnalyticsContextValue>({
  state: { ...defaultState },
});

export function useAnalyticsContext() {
  return useContext<AnalyticsContextValue>(AnalyticsContext);
}

export const AnalyticsContextProvider: React.FC = ({ children }) => {
  return <AnalyticsContext.Provider value={{ state: Analytics }}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContext;
