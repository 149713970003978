import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useState, VFC } from "react";
import { LogWorkDialog, LogWorkDialogProps } from "./dialogs/LogWorkDialog";

export type EasyLogWorkDialogOpenerProps = Omit<LogWorkDialogProps, "open" | "onCancel"> &
  Partial<Pick<LogWorkDialogProps, "onCancel">>;

const propsAtom = atom<EasyLogWorkDialogOpenerProps | undefined>(undefined);
const propsWriteAtom = atom(null, (get, set, newProps: EasyLogWorkDialogOpenerProps | undefined) =>
  set(propsAtom, newProps)
);

export const EasyLogWorkDialog: VFC = () => {
  const [props] = useAtom(propsAtom);
  const [open, setOpen] = useState(false);
  const onCancel = props?.onCancel;
  const onSubmit = props?.onSubmit;

  const handleClose = useCallback<NonNullable<LogWorkDialogProps["onCancel"]>>(() => {
    setOpen(false);
    onCancel?.();
  }, [onCancel]);

  const handleSubmit = useCallback<NonNullable<LogWorkDialogProps["onSubmit"]>>(async (endTime, duration) => {
    await onSubmit?.(endTime, duration);
    handleClose();
  }, [handleClose, onSubmit]);

  useEffect(() => {
    if (props) setOpen(true);
  }, [props]);

  return props ? <LogWorkDialog open={open} {...props} onCancel={handleClose} onSubmit={handleSubmit} /> : <></>;
};

export type EasyLogWorkDialogOpener<P extends Partial<EasyLogWorkDialogOpenerProps>> = (
  props: Partial<EasyLogWorkDialogOpenerProps> & Omit<EasyLogWorkDialogOpenerProps, keyof P>
) => void;

export const useEasyLogWorkDialog = <P extends Partial<EasyLogWorkDialogOpenerProps>>(
  baseProps: P
): EasyLogWorkDialogOpener<P> => {
  const [, setProps] = useAtom(propsWriteAtom);
  return useCallback<EasyLogWorkDialogOpener<P>>(
    (props) =>
      setProps({
        ...baseProps,
        ...props,
      } as EasyLogWorkDialogOpenerProps),
    [baseProps, setProps]
  );
};
