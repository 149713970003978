import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useState, VFC } from "react";
import { SnoozeOption } from "../reclaim-api/Events";
import { ReschedulatorPopper, ReschedulatorPopperProps } from "./ReschedulatorPopper";

export type EasyReschedulatorOpenerProps<S extends string = SnoozeOption> = Omit<ReschedulatorPopperProps<S>, "open">;

const propsAtom = atom<EasyReschedulatorOpenerProps | undefined>(undefined);
const propsWriteAtom = atom(null, (get, set, newProps: EasyReschedulatorOpenerProps | undefined) =>
  set(propsAtom, newProps)
);

export const EasyReschedulator: VFC = () => {
  const [props] = useAtom(propsAtom);
  const [open, setOpen] = useState(false);
  const onClose = props?.onClose;

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (props) setOpen(true);
  }, [props]);

  return props ? <ReschedulatorPopper open={open} {...props} onClose={handleClose} /> : <></>;
};
export type EasyReschedulatorOpener<
  P extends Partial<EasyReschedulatorOpenerProps<S>>,
  S extends string = SnoozeOption
> = (props: Partial<EasyReschedulatorOpenerProps<S>> & Omit<EasyReschedulatorOpenerProps<S>, keyof P>) => void;

export const useEasyReschedulator = <P extends Partial<ReschedulatorPopperProps<S>>, S extends string = SnoozeOption>(
  baseProps: P
): EasyReschedulatorOpener<P, S> => {
  const [, setProps] = useAtom(propsWriteAtom);
  return useCallback<EasyReschedulatorOpener<P, S>>(
    (props) =>
      setProps({
        ...baseProps,
        ...props,
      } as EasyReschedulatorOpenerProps),
    [baseProps, setProps]
  );
};
