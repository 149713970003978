export type PromisedDebounceFn<T> = {
  (): Promise<T>;
  cancel: () => void;
};

export type PromisedDebounceCb<T> = () => PromiseLike<T> | T;

/**
 * Similar to _.debounce, only all debounced functions return a promise which resolves to the value (promise or otherwise) returned by `cb`
 * @param cb The callback to debounce.  Also comes attached with a `cancel` function
 * @param waitMs The amount of time to wait before running the callback
 * @returns A new debounced function
 */
export const promisedDebounce = <T>(cb: PromisedDebounceCb<T>, waitMs: number): PromisedDebounceFn<T> => {
  let promise: Promise<T> | undefined;
  let res: ((value: T | PromiseLike<T>) => void) | undefined;
  let timer: ReturnType<typeof setTimeout>;
  let awaitingCb = false;

  const fn = (() => {
    if (!promise) promise = new Promise((innerRes) => (res = innerRes));

    // if the CB is already running don't start
    // a new timer, just glob on future calls to
    // the existing promise
    if (!awaitingCb) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        awaitingCb = true;
        const val = await cb();
        awaitingCb = false;
        res?.(val);
        promise = undefined;
      }, waitMs);
    }

    return promise;
  }) as PromisedDebounceFn<T>;

  fn.cancel = () => {
    clearTimeout(timer);
    res = undefined;
  };

  return Object.freeze(fn);
};
