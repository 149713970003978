/// <reference types="@types/segment-analytics" />
/// <reference types="@sentry/types" />

import * as Sentry from "@sentry/nextjs";
import { Browser } from "./utils/platform";

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS;
  }
}

export const BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_ENV || "unknown";
export const ENV = process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV;

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY;

const ready: Record<string, boolean | undefined> = {
  segment: undefined,
  intercom: undefined,
  sentry: undefined,
};

function initSentry() {
  if (undefined !== ready.sentry) return ready.sentry;

  if (!!SENTRY_DSN) {
    ready.sentry = false;
    console.debug("Initializing Sentry");

    // initialized automatically w/ configs in `src/sentry.*.js`
    ready.sentry = !!Sentry;
  }

  return true;
}

function initSegment() {
  if (undefined !== ready.segment) return ready.segment;

  if (!!SEGMENT_KEY) {
    if (!window.analytics?.load) {
      console.warn("Unable to load Segment, window.analytics.load is undefined");
      ready.segment = undefined;
      return false;
    }

    ready.segment = false;
    console.debug("Initializing Segment");

    window.analytics.load(SEGMENT_KEY);
    window.analytics.identify(
      undefined,
      {
        context: {
          app: {
            name: window.location.host,
            build: BUILD_ID,
            env: ENV,
          },
        },
        // TODO SegmentAnalytics.SegmentOpts (but, for real)
      },
      () => {
        console.log("Segment ready");

        // Track initial url
        window.analytics.page({
          href: window.location.href,
          host: window.location.host,
        });

          if (!window.intercomSettings) window.intercomSettings = {};
          window.intercomSettings.hide_default_launcher = true;
          window.intercomSettings.custom_launcher_selector = '#IntercomHelpButton';

        ready.segment = true;
      }
    );
  }

  return true;
}

function initIntercom() {
  // Intercom is loaded by Segment, so make sure that's loaded first
  if (undefined === ready.segment) initSegment();
  return (ready.intercom = !!window?.Intercom);
}

type Analytics = {
  ready: typeof ready;

  sentry?: typeof Sentry;

  segment?: SegmentAnalytics.AnalyticsJS;
  intercom?: Intercom_.IntercomStatic;
};

export const Analytics: Analytics = {
  ready,

  /** Node and browser */
  get sentry() {
    if (!ready.sentry) initSentry();
    return Sentry;
  },

  /** Browser only */
  get segment() {
    if (Browser.isBrowser() && !ready.segment) initSegment();
    return Browser.isBrowser() ? window.analytics : undefined; // Segment global
  },
  get intercom() {
    if (Browser.isBrowser() && !ready.intercom) initIntercom();
    return Browser.isBrowser() ? window?.Intercom : undefined; // Intercom global
  },
};

export default Analytics;
