import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import React, { VFC } from "react";
import { LoadingIcon } from "../components/LoadingIcon";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "100%",
    },
  }),
  {
    classNamePrefix: "Loading",
  }
);

export type LoadingJSSClassKey = keyof ReturnType<typeof useStyles>;

export type LoadingProps = {
  classes?: Partial<ClassNameMap<LoadingJSSClassKey>>;
  className?: string;
  size?: number | string;
};

export const Loading: VFC<LoadingProps> = ({ className, classes: extClasses, size }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Box className={clsx(classes.root, className)}>
      <Box role="img" aria-label="Loading">
        <LoadingIcon size={size || 64} />
      </Box>
    </Box>
  );
};
