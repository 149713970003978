import { useUserContext } from "../context/UserContext";
import { useTeamState } from "../hooks/atoms/useTeam";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { Team } from "../reclaim-api/team/Team";
import { User } from "../reclaim-api/Users";
import { DevRegetData } from "./dev.types";

export const fetchDevApi = async <R>(
  action: string,
  options: { method?: "GET" | "POST"; data?: unknown } = {}
): Promise<R> => {
  const { method = "GET", data } = options;
  let url = `/api/dev/${action}`;
  let body: string | undefined;

  switch (method) {
    case "GET":
      url += `?${Object.entries(data || {})
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`;
      break;
    case "POST":
      switch (typeof data) {
        case "undefined":
          break;
        case "string":
          body = data;
          break;
        default:
          body = JSON.stringify(data);
          break;
      }
      break;
  }

  const response = await fetch(url, {
    method: "POST",
    body,
  });

  try {
    return response.json();
  } catch {
    return response.text() as R;
  }
};

export async function devReconcile(type: "plans"): Promise<void>;
export async function devReconcile(type: "team" | "user", id: string): Promise<void>;
export async function devReconcile(type: "team" | "user" | "plans", id?: string): Promise<void> {
  await fetchDevApi("reconcile", {
    method: "POST",
    data: {
      type,
      id,
    },
  });
}

export const runDevAction = (
  key: string,
  user: User,
  team: Team,
  args: Record<string, unknown>
): Promise<DevRegetData> =>
  fetchDevApi<DevRegetData>("runDevAction", {
    method: "POST",
    data: {
      key,
      args: {
        user_id: user.id,
        team_id: team.id,
        ...args,
      },
    },
  });

export const useDevApi = () => {
  const [{ user }] = useUserContext();
  const { team } = useTeamState();

  const reconcileTeam = useCallbackSafeRef(() => team && devReconcile("team", team.id + ""));
  const reconcileUser = useCallbackSafeRef(() => user && devReconcile("user", user.id + ""));
  const reconcilePlans = useCallbackSafeRef(() => devReconcile("plans"));

  const runAction = useCallbackSafeRef(
    (key: string, args: Record<string, unknown>) => (user && team && runDevAction(key, user, team, args)) || undefined
  );

  return { runAction, reconcile: devReconcile, reconcileTeam, reconcileUser, reconcilePlans };
};
