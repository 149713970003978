import { map, Operator, pipe, scan, subscribe } from "wonka";
import { sourceT } from "wonka/dist/types/src/Wonka_types.gen";
import { Envelope } from "../reclaim-api/adaptors/ws";

/**
 * Check to determine if a websocket payload is an Envelope
 *
 * @param object Either a deserialized websocket Envelope, or something else
 * @returns true if object is an Envelope, false otherwise
 */
export function isEnvelope<D = unknown>(object: Envelope<D> | unknown): object is Envelope<D> {
  // TODO (IW): Check data matches D
  return (object as Envelope<D>).data !== undefined && (object as Envelope<D>).compressed !== undefined;
}

/**
 * Options for the awaitSource function
 */
export type AwaitSourceOptions<T> = {
  /**
   * Receives every response piped in from source.  If readResponse returns true, that response will be immediately picked to resolve the Promise.
   */
  readResponse?: (response: T) => boolean;
  /**
   * milliseconds before timing out.
   */
  timeoutMS?: number;
};

/**
 * Returns a promise with the value from the first update from observable$
 * @param observable$ The observable to watch
 * @param minWaitMS The minimum time to wait before reporting a value
 * @returns A promise with the value from observable$
 */
export const awaitSource = async <T>(
  observable$: sourceT<T>,
  options: AwaitSourceOptions<T> = {}
): Promise<T | undefined> => {
  const { readResponse = () => true, timeoutMS } = options;

  return new Promise((res) => {
    let timer: ReturnType<typeof setTimeout>;

    const { unsubscribe } = pipe(
      observable$,
      subscribe((value) => {
        if (readResponse(value)) {
          clearTimeout(timer);
          unsubscribe();
          res(value);
        }
      })
    );

    if (typeof timeoutMS === "number")
      timer = setTimeout(() => {
        unsubscribe();
        res(undefined);
      }, timeoutMS);
  });
};

export const deserialize = <R = unknown, T = unknown>(
  deser: (dto: T) => R
): Operator<Envelope<T | T[]> | T[] | T | null, R[]> =>
  map<Envelope<T> | T[] | T | null, R[]>((val): R[] => {
    // If there's no value, return empty list
    if (!val) return [];

    // If val is an envelope, extract the data
    const data = isEnvelope(val) ? val.data : val;

    // Coerce data into a list
    const items: T[] = Array.isArray(data) ? data : [data];

    // Deserialize items
    return items.map(deser);
  });

/**
 * Accumulate a list of items by adding, updating, and removing source data by pk
 *
 * @param getPk callback that takes an item and returns the primary key of the item
 * @returns Accumulated list after updating existing and adding new items
 */
export const upsert = <T = unknown>(
  getPk: (item: T) => string | number
): Operator<Envelope<T | T[]> | T[] | T | null, T[]> =>
  scan<Envelope<T> | T[] | T | null, T[]>((acc: T[], val): T[] => {
    // If there's no value, return empty list
    if (!val) return acc;

    // If val is an envelope, extract the data
    const data = isEnvelope(val) ? val.data : val;

    // Coerce data into a list
    const items: T[] = Array.isArray(data) ? data : [data];

    // Add, update or delete each item based on pk
    items.forEach((i) => {
      const pk = getPk(i);
      const deleted = !!i["deleted"]; // FIXME (IW): Assumes item uses a param for deleted state
      const index = undefined !== pk ? acc.findIndex((record) => getPk(record) === pk) : -1;

      if (undefined === pk) console.warn("No PK for item", i);

      if (!!deleted) console.log("item deleted", pk, index, i);

      if (index === -1 && !deleted) acc.push(i);
      else !deleted ? acc.splice(index, 1, i) : acc.splice(index, 1);
    });

    return acc;
  }, [] as T[]);
