import { VFC } from "react";
import { useQuestsNotifications } from "../../hooks/atoms/useQuestsNotifications";

/**
 * This component just attaches the quest notifications and should only be used
 * in App.tsx through EasyAccessElements.tsx. This hook needs to be global but within the 
 * context of the snackbar provider (notistack in App.tsx).
 */
export const QuestsNotifications: VFC = () => {
  useQuestsNotifications();
  return null;
}