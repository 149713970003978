import { useCallback } from "react";
import { useShortcut } from "./useShortcut";

export const useMicroShortcuts = (submitCallback: () => void, cancelCallback: () => void) => {
  useShortcut(
    "Escape",
    useCallback(
      (e) => {
        // Prevent other key listeners from hijacking
        e.stopPropagation();
        cancelCallback();
      },
      [cancelCallback]
    )
  );

  useShortcut(
    "Enter",
    useCallback(
      (e) => {
        // Prevent other key listeners from hijacking
        e.stopPropagation();
        submitCallback();
      },
      [submitCallback]
    )
  );
};
