import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import { DayOfWeek } from "../reclaim-api/Calendars";
import { Recurrence } from "../reclaim-api/OneOnOnes";
import { daysStr, getDurationString } from "./dates";
import { pluralize } from "./strings";

/**
 * Returns a promise that resolves after ms milliseconds
 * @param ms the number of milliseconds to wait
 * @returns A valueless promise
 */
export const awaitTime = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

export const parseTime = (value: string, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;

  // help lazy input look like something chrono can parse (eg. "1" => "1:00")
  let newValue = /^\d{0,2}$/.test(value.trim()) ? `${value}:00` : value;
  // 1:00a => 1:00 a
  newValue = newValue.replace(/(?![\d]+)([ap]m?)/i, (m) => ` ${m.toLowerCase()}`);
  // 2030 => 8:30 pm (note: limit length to eliminate conflicts with long dates)
  if (newValue.length <= 4) {
    newValue = newValue.replace(/([\d]{1,2}(?!:)[\d]{2})/i, (m) => `${m.slice(0, -2)}:${m.slice(-2)}`);
  }

  return parseDate(newValue, undefined, options);
};

export const getRecurrenceDisplay = (daysActive?: DayOfWeek[], recurrence?: Recurrence, frequencyPerWeek?: number) => {
  let resolvedRecurrence = recurrence;

  if (!recurrence || recurrence === Recurrence.Weekly) {
    resolvedRecurrence =
      !!daysActive?.length && (daysActive.length === frequencyPerWeek || !frequencyPerWeek) && daysActive?.length > 1
        ? Recurrence.Daily
        : Recurrence.Weekly;
  }

  const frequecyDisplay =
    !frequencyPerWeek || frequencyPerWeek === 1 ? "" : `${frequencyPerWeek} ${pluralize(frequencyPerWeek, "time")}, `;

  return `${resolvedRecurrence}${daysActive?.length !== 7 ? `, ${frequecyDisplay}${daysStr(daysActive || [])}` : ""}`;
};

export const getDurationsDisplay = (minInMs: number, maxInMs: number) =>
  `${getDurationString(minInMs)}${minInMs !== maxInMs ? ` - ${getDurationString(maxInMs)}` : ""}`;
